'use strict';

import $ from 'jquery';
import { Plugin } from '../../../../../node_modules/foundation-sites/js/foundation.plugin';
import { GetYoDigits } from '../../../../../node_modules/foundation-sites/js/foundation.util.core';
import Slick from '../../../../../node_modules/slick-carousel/slick/slick.js';


class Slider extends Plugin {
    _setup(element, options) {
        this.$element = element;
        this.options = $.extend({}, Slider.defaults, this.$element.data(), options);

        this.id = this.$element[0].id;
        var id = this.$element[0].id || GetYoDigits(6, 'slider');
        this.$slidesDottes = this.$element.find(`.${this.options.slidesDottesClass}`).clone();
        this.$slider = this.$element.find(`.${this.options.sliderClass}`);

        this.slidesDottesArray = this.$slidesDottes.children().clone();
        this.$element.attr({
            'id': id
        });
        this._init();

    }
    _init() {
        var _this = this;
        if (typeof this.$slidesDottes !== "undefined") {
            this.options.customPaging = function(slider, i) {
                return _this.slidesDottesArray[i];
            };
        }
        this.$slider.slick(this.options);
        this._events();
    }

    _events() {
        var _this = this;
        this.$slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            $('.slick-current').addClass('slick-fading');

        });
        this.$slider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
            $('.slick-slide').removeClass('slick-fading')
        });
    }

}
Slider.defaults = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    fade: true,
    dots: true,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    prevArrow: $('#previous_button'),
    nextArrow: $('#next_button'),

    /**
     * Class applied to individual slider.
     * @option
     * @type {string}
     * @default 'slick-slide'
     */
    sliderClass: 'slider',
    /**
     * Class applied to individual slides.
     * @option
     * @type {string}
     * @default 'slick-slide'
     */
    slideClass: 'slide',
    /**
     * Class applied to individual slider-dottes.
     * @option
     * @type {string}
     * @default 'slick-slide'
     */
    slidesDottesClass: 'slider-dottes'
}

export { Slider };