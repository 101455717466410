import $ from 'jquery';
import { Plugin } from '../../../../../node_modules/foundation-sites/js/foundation.plugin';
import { GetYoDigits } from '../../../../../node_modules/foundation-sites/js/foundation.util.core';


class Square extends Plugin {
    _setup(element, options) {
        this.$element = element;
        this.options = $.extend({}, Square.defaults, this.$element.data(), options);
        this._init();
    }
    _init() {
        var id = this.$element[0].id || GetYoDigits(6, 'square');
        this.$element.attr({
            'id': id,
        });
        this.$element.height(this.$element.width());
        this._events();
    }
    _events() {
        var _this = this;
        $(window).on('resize', function() {
            _this.$element.height(_this.$element.width());
        })
    }
}

Square.defaults = {}


export { Square };