'use strict';
/**
 * Topbar module.
 * @module foundation.sticky
 * @requires foundation.util.triggers
 * @requires foundation.util.mediaQuery
 */



'use strict';

import $ from 'jquery';
import { Plugin } from '../../../../../node_modules/foundation-sites/js/foundation.plugin';
import { GetYoDigits } from '../../../../../node_modules/foundation-sites/js/foundation.util.core';


class Topbar extends Plugin {
    _setup(element, options) {
        this.$element = element;
        this.options = $.extend({}, Topbar.defaults, this.$element.data(), options);
        this.$topbar = this.$element.find(`.${this.options.pauseButtonClass}`);

        this.id = this.$element[0].id;
        this._init();

    }
    _init() {
        var id = this.$element[0].id || GetYoDigits(6, 'topbar');
        this.$element.attr({
            'id': id
        });
        this.scrollCount = this.options.checkEvery;
        this.isStuck = true;
        this._events(id.split('-').reverse().join('-'));
    }
    _events(id) {
        var _this = this,
            scrollListener = this.scrollListener = `scroll.zf.${id}`;
        $(window).off(scrollListener).on(scrollListener, function(e) {
            if (_this.scrollCount === 0) {
                _this.scrollCount = _this.options.checkEvery;
                _this._calc(window.pageYOffset);
            } else {
                _this.scrollCount--;
                _this._calc(window.pageYOffset);
            }
        })
    }

    _calc(scroll) {
        if (!scroll) { scroll = window.pageYOffset; }

        if (scroll >= 10) {
            if (this.isStuck) {
                this._removeTopClass();
            }
        } else {
            if (!this.isStuck) {
                this._setTopClass();
            }
        }
    }

    _setTopClass() {
        var _this = this;
        this.$element.removeClass(`${_this.options.onTopClass}`).removeClass(`${_this.options.notOnTopClass}`)
            .addClass(`${_this.options.onTopClass}`);
        this.isStuck = true;
    }

    _removeTopClass() {
        var _this = this;
        this.$element.removeClass(`${_this.options.onTopClass}`).removeClass(`${_this.options.notOnTopClass}`)
            .addClass(`${_this.options.notOnTopClass}`);
        this.isStuck = false;
    }

}

Topbar.defaults = {
    topbarClass: 'top-bar',

    onTopClass: 'onTop',

    notOnTopClass: 'notOnTop',
    /**
     * Number of scroll events between the plugin's recalculating sticky points. Setting it to `0` will cause it to recalc every scroll event, setting it to `-1` will prevent recalc on scroll.
     * @option
     * @type {number}
     * @default -1
     */
    checkEvery: -1
}

export { Topbar };