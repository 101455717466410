/*********************************************************************
* Based on:
   #### Twitter Post Fetcher v18.0.2 ####
*  Coded by Jason Mayes 2015. A present to all the developers out there.
*  www.jasonmayes.com
*  Please keep this disclaimer with my code if you use it. Thanks. :-)
*  Got feedback or questions, ask here:
*  http://www.jasonmayes.com/projects/twitterApi/
*  Github: https://github.com/jasonmayes/Twitter-Post-Fetcher
*  Updates will be posted to this site.
*********************************************************************/


'use strict';

import $ from 'jquery';
import { Plugin } from '../../../../../node_modules/foundation-sites/js/foundation.plugin';
import { GetYoDigits } from '../../../../../node_modules/foundation-sites/js/foundation.util.core';
import Slick from '../../../../../node_modules/slick-carousel/slick/slick.js';


class Feed extends Plugin {

    _setup(element, options) {
        this.$element = element;
        this.options = $.extend({}, { "customCallback": this._getTweets }, Feed.defaults, this.$element.data, options);
        this.inProgress = false;
        window.__twttrf = this;
        window.twitterFetcher = this;
        this._init();
    }
    _init() {
        var id = this.$element[0].id || GetYoDigits(6, 'feed');
        this.$element.attr({
            'id': id,
        });
        this._fetch(this.options);
        // Prepend polyfill for IE/Edge.
        (function(arr) {
            arr.forEach(function(item) {
                if (item.hasOwnProperty('prepend')) {
                    return;
                }
                Object.defineProperty(item, 'prepend', {
                    configurable: true,
                    enumerable: true,
                    writable: true,
                    value: function prepend() {
                        var argArr = Array.prototype.slice.call(arguments),
                            docFrag = document.createDocumentFragment();

                        argArr.forEach(function(argItem) {
                            var isNode = argItem instanceof Node;
                            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                        });
                        this.insertBefore(docFrag, this.firstChild);
                    }
                });
            });
        })([Element.prototype, Document.prototype, DocumentFragment.prototype]);
    }

    _targetLinksToNewWindow(el) {
        var links = el.getElementsByTagName('a');
        for (var i = links.length - 1; i >= 0; i--) {
            links[i].setAttribute('target', '_blank');
            links[i].setAttribute('rel', 'noopener');
        }
    }

    _strip(data) {
        return data.replace(/<b[^>]*>(.*?)<\/b>/gi, function(a, s) { return s; })
            .replace(/class="(?!(tco-hidden|tco-display|tco-ellipsis))+.*?"|data-query-source=".*?"|dir=".*?"|rel=".*?"/gi,
                '');
    }

    _handleTweets(tweets) {
        if (this.options.customCallbackFunction === null) {
            var x = tweets.length;
            var n = 0;
            var element = document.getElementById(this.options.domNode);
            var html = '<ul>';
            while (n < x) {
                html += '<li>' + tweets[n] + '</li>';
                n++;
            }
            html += '</ul>';
            this.$element.html(html);
        } else {
            this.options.customCallbackFunction(tweets);
        }
    }

    _fetch() {

        if (this.options.maxTweets === undefined) {
            this.options.maxTweets = 20;
        }
        if (this.options.enableLinks === undefined) {
            this.options.enableLinks = true;
        }
        if (this.options.showUser === undefined) {
            this.options.showUser = true;
        }
        if (this.options.showTime === undefined) {
            this.options.showTime = true;
        }
        if (this.options.dateFunction === undefined) {
            this.options.dateFunction = 'default';
        }
        if (this.options.showRetweet === undefined) {
            this.options.showRetweet = true;
        }
        if (this.options.customCallback === undefined) {
            this.options.customCallback = null;
        }
        if (this.options.showInteraction === undefined) {
            this.options.showInteraction = true;
        }
        if (this.options.showImages === undefined) {
            this.options.showImages = false;
        }
        if (this.options.useEmoji === undefined) {
            this.options.useEmoji = false;
        }
        if (this.options.linksInNewWindow === undefined) {
            this.options.linksInNewWindow = true;
        }
        if (this.options.showPermalinks === undefined) {
            this.options.showPermalinks = true;
        }
        if (this.options.dataOnly === undefined) {
            this.options.dataOnly = false;
        }

        if (this.inProgress) {
            this.options.queue.push(this.options);
        } else {
            this.inProgress = true;

            var domNode = this.options.domId;
            var maxTweets = this.options.maxTweets;
            var parseLinks = this.options.enableLinks;
            var printUser = this.options.showUser;
            var printTime = this.options.showTime;
            var showRts = this.options.showRetweet;
            var formatterFunction = this.options.dateFunction;
            var customCallbackFunction = this.options.customCallback;
            var showInteractionLinks = this.options.showInteraction;
            var showImages = this.options.showImages;
            var useEmoji = this.options.useEmoji;
            var targetBlank = this.options.linksInNewWindow;
            var permalinks = this.options.showPermalinks;
            var dataOnly = this.options.dataOnly;

            var head = document.getElementsByTagName('head')[0];
            if (this.options.script !== null) {
                head.removeChild(script);
            }
            this.options.script = document.createElement('script');
            this.options.script.type = 'text/javascript';
            if (this.options.list !== undefined) {
                this.options.script.src = 'https://syndication.twitter.com/timeline/list?' +
                    'callback=__twttrf._callback&dnt=false&list_slug=' +
                    this.options.list.listSlug + '&screen_name=' + this.options.list.screenName +
                    '&suppress_response_codes=true&lang=' + (this.options.lang || lang) +
                    '&rnd=' + Math.random();
            } else if (this.options.profile !== undefined) {
                this.options.script.src = 'https://syndication.twitter.com/timeline/profile?' +
                    'callback=__twttrf._callback&dnt=false' +
                    '&screen_name=' + this.options.profile.screenName +
                    '&suppress_response_codes=true&lang=' + (this.options.lang || lang) +
                    '&rnd=' + Math.random();
            } else if (this.options.likes !== undefined) {
                this.options.script.src = 'https://syndication.twitter.com/timeline/likes?' +
                    'callback=__twttrf._callback&dnt=false' +
                    '&screen_name=' + this.options.likes.screenName +
                    '&suppress_response_codes=true&lang=' + (this.options.lang || lang) +
                    '&rnd=' + Math.random();
            } else {
                this.options.script.src = 'https://cdn.syndication.twimg.com/widgets/timelines/' +
                    this.options.id + '?&lang=' + (this.options.lang || lang) +
                    '&callback=__twttrf._callback&' +
                    'suppress_response_codes=true&rnd=' + Math.random();
            }
            head.appendChild(this.options.script);
        }
    }

    _callback(data) {
        var _this = this;
        if (data === undefined || data.body === undefined) {
            _this.inProgress = false;

            if (_this.options.queue.length > 0) {
                twitterFetcher.fetch(queue[0]);
                _this.options.queue.splice(0, 1);
            }
            return data;
        }

        // Remove emoji and summary card images.
        if (!_this.options.useEmoji) {
            data.body = data.body.replace(/(<img[^c]*class="Emoji[^>]*>)|(<img[^c]*class="u-block[^>]*>)/g, '');
        }

        // Remove display images.
        if (!_this.options.showImages) {
            data.body = data.body.replace(/(<img[^c]*class="NaturalImage-image[^>]*>|(<img[^c]*class="CroppedImage-image[^>]*>))/g, '');
        }
        // Remove avatar images.
        if (!_this.options.printUser) {
            data.body = data.body.replace(/(<img[^c]*class="Avatar"[^>]*>)/g, '');
        }

        var div = document.createElement('div');
        div.innerHTML = data.body;
        if (typeof(div.getElementsByClassName) === 'undefined') {
            _this.options.supportsClassName = false;
        }

        function swapDataSrc(element) {
            var avatarImg = element.getElementsByTagName('img')[0];
            if (avatarImg) {
                avatarImg.src = avatarImg.getAttribute('data-src-2x');
            } else {
                var screenName = element.getElementsByTagName('a')[0]
                    .getAttribute('href').split('twitter.com/')[1];
                var img = document.createElement('img');
                img.setAttribute('src', 'https://twitter.com/' + screenName +
                    '/profile_image?size=bigger');
                element.prepend(img);
            }
            return element;
        }

        var tweets = [];
        var authors = [];
        var times = [];
        var images = [];
        var rts = [];
        var tids = [];
        var permalinksURL = [];
        var x = 0;

        if (_this.options.supportsClassName) {
            var tmp = div.getElementsByClassName('timeline-Tweet');
            while (x < tmp.length) {
                if (tmp[x].getElementsByClassName('timeline-Tweet-retweetCredit').length > 0) {
                    rts.push(true);
                } else {
                    rts.push(false);
                }
                if (!rts[x] || rts[x] && _this.options.showRts) {
                    tweets.push(tmp[x].getElementsByClassName('timeline-Tweet-text')[0]);
                    tids.push(tmp[x].getAttribute('data-tweet-id'));
                    if (_this.options.printUser) {
                        authors.push(swapDataSrc(tmp[x].getElementsByClassName('timeline-Tweet-author')[0]));
                    }
                    times.push(tmp[x].getElementsByClassName('dt-updated')[0]);
                    permalinksURL.push(tmp[x].getElementsByClassName('timeline-Tweet-timestamp')[0]);
                    if (tmp[x].getElementsByClassName('timeline-Tweet-media')[0] !==
                        undefined) {
                        images.push(tmp[x].getElementsByClassName('timeline-Tweet-media')[0]);
                    } else {
                        images.push(undefined);
                    }
                }
                x++;
            }
        } else {
            var tmp = getElementsByClassName(div, 'timeline-Tweet');
            while (x < tmp.length) {
                if (getElementsByClassName(tmp[x], 'timeline-Tweet-retweetCredit').length > 0) {
                    rts.push(true);
                } else {
                    rts.push(false);
                }
                if (!rts[x] || rts[x] && showRts) {
                    tweets.push(getElementsByClassName(tmp[x], 'timeline-Tweet-text')[0]);
                    tids.push(tmp[x].getAttribute('data-tweet-id'));
                    if (_this.options.printUser) {
                        authors.push(swapDataSrc(getElementsByClassName(tmp[x], 'timeline-Tweet-author')[0]));
                    }
                    times.push(getElementsByClassName(tmp[x], 'dt-updated')[0]);
                    permalinksURL.push(getElementsByClassName(tmp[x], 'timeline-Tweet-timestamp')[0]);
                    if (getElementsByClassName(tmp[x], 'timeline-Tweet-media')[0] !== undefined) {
                        images.push(getElementsByClassName(tmp[x], 'timeline-Tweet-media')[0]);
                    } else {
                        images.push(undefined);
                    }
                }
                x++;
            }
        }

        if (tweets.length > _this.options.maxTweets) {
            tweets.splice(_this.options.maxTweets, (tweets.length - _this.options.maxTweets));
            authors.splice(_this.options.maxTweets, (authors.length - _this.options.maxTweets));
            times.splice(_this.options.maxTweets, (times.length - _this.options.maxTweets));
            rts.splice(_this.options.maxTweets, (rts.length - _this.options.maxTweets));
            images.splice(_this.options.maxTweets, (images.length - _this.options.maxTweets));
            permalinksURL.splice(_this.options.maxTweets, (permalinksURL.length - _this.options.maxTweets));
        }

        var arrayTweets = [];
        var x = tweets.length;
        var n = 0;
        if (_this.options.dataOnly) {
            while (n < x) {
                arrayTweets.push({
                    tweet: tweets[n].innerHTML,
                    author: authors[n] ? authors[n].innerHTML : 'Unknown Author',
                    author_data: {
                        profile_url: authors[n] ? authors[n].querySelector('[data-scribe="element:user_link"]').href : null,
                        profile_image: authors[n] ?
                            'https://twitter.com/' + authors[n].querySelector('[data-scribe="element:screen_name"]').title.split('@')[1] + '/profile_image?size=bigger' : null,
                        profile_image_2x: authors[n] ? 'https://twitter.com/' + authors[n].querySelector('[data-scribe="element:screen_name"]').title.split('@')[1] + '/profile_image?size=original' : null,
                        screen_name: authors[n] ? authors[n].querySelector('[data-scribe="element:screen_name"]').title : null,
                        name: authors[n] ? authors[n].querySelector('[data-scribe="element:name"]').title : null
                    },
                    time: times[n].textContent,
                    timestamp: times[n].getAttribute('datetime').replace('+0000', 'Z').replace(/([\+\-])(\d\d)(\d\d)/, '$1$2:$3'),
                    image: extractImageUrl(images[n]),
                    rt: rts[n],
                    tid: tids[n],
                    permalinkURL: (permalinksURL[n] === undefined) ?
                        '' : permalinksURL[n].href
                });
                n++;
            }
        } else {
            while (n < x) {
                if (typeof(_this.options.formatterFunction) !== 'string') {
                    var datetimeText = times[n].getAttribute('datetime');
                    var newDate = new Date(times[n].getAttribute('datetime')
                        .replace(/-/g, '/').replace('T', ' ').split('+')[0]);
                    var dateString = datetimeText;
                    // var dateString = _this.options.formatterFunction(newDate, datetimeText);
                    times[n].setAttribute('aria-label', dateString);

                    if (tweets[n].textContent) {
                        // IE hack.
                        if (_this.options.supportsClassName) {
                            times[n].textContent = dateString;
                        } else {
                            var h = document.createElement('p');
                            var t = document.createTextNode(dateString);
                            h.appendChild(t);
                            h.setAttribute('aria-label', dateString);
                            times[n] = h;
                        }
                    } else {
                        times[n].textContent = dateString;
                    }
                }
                var op = '';
                if (_this.options.parseLinks) {
                    if (_this.options.targetBlank) {
                        _this._targetLinksToNewWindow(tweets[n]);
                        if (_this.options.printUser) {
                            _this._targetLinksToNewWindow(authors[n]);
                        }
                    }
                    if (_this.options.printUser) {
                        op += '<div class="user">' + _this._strip(authors[n].innerHTML) +
                            '</div>';
                    }
                    op += '<p class="tweet">' + _this._strip(tweets[n].innerHTML) + '</p>';
                    if (_this.options.printTime) {
                        if (_this.options.permalinks) {
                            op += '<p class="timePosted"><a href="' + permalinksURL[n] +
                                '">' + times[n].getAttribute('aria-label') + '</a></p>';
                        } else {
                            op += '<p class="timePosted">' +
                                times[n].getAttribute('aria-label') + '</p>';
                        }
                    }
                } else {
                    if (tweets[n].textContent) {
                        if (_this.options.printUser) {
                            op += '<p class="user">' + authors[n].textContent + '</p>';
                        }
                        op += '<p class="tweet">' + tweets[n].textContent + '</p>';
                        if (_this.options.printTime) {
                            op += '<p class="timePosted">' + times[n].textContent + '</p>';
                        }

                    } else {
                        if (_this.options.printUser) {
                            op += '<p class="user">' + authors[n].textContent + '</p>';
                        }
                        op += '<p class="tweet">' + tweets[n].textContent + '</p>';
                        if (_this.options.printTime) {
                            op += '<p class="timePosted">' + times[n].textContent + '</p>';
                        }
                    }
                }
                if (_this.options.showInteractionLinks) {
                    op += '<p class="interact"><a href="https://twitter.com/intent/' +
                        'tweet?in_reply_to=' + tids[n] +
                        '" class="twitter_reply_icon"' +
                        (_this.options.targetBlank ? ' target="_blank" rel="noopener">' : '>') +
                        'Reply</a><a href="https://twitter.com/intent/retweet?' +
                        'tweet_id=' + tids[n] + '" class="twitter_retweet_icon"' +
                        (_this.options.targetBlank ? ' target="_blank" rel="noopener">' : '>') + 'Retweet</a>' +
                        '<a href="https://twitter.com/intent/favorite?tweet_id=' +
                        tids[n] + '" class="twitter_fav_icon"' +
                        (_this.options.targetBlank ? ' target="_blank" rel="noopener">' : '>') + 'Favorite</a></p>';
                }
                if (_this.options.showImages && images[n] !== undefined && extractImageUrl(images[n]) !== undefined) {
                    op += '<div class="media">' +
                        '<img src="' + extractImageUrl(images[n]) +
                        '" alt="Image from tweet" />' + '</div>';
                }
                if (_this.options.showImages) {
                    arrayTweets.push(op);
                } else if (!_this.options.showImages && tweets[n].textContent.length) {
                    arrayTweets.push(op);
                }

                n++;
            }
        }

        _this._handleTweets(arrayTweets);
        _this.inProgress = false;

        if (_this.options.queue.length > 0) {
            twitterFetcher.fetch(queue[0]);
            _this.options.queue.splice(0, 1);
        }
    }
}

Feed.defaults = {
    "profile": { "screenName": 'enwayTech' },
    "dataOnly": true,
    "domNode": '',
    "maxTweets": 20,
    "parseLinks": true,
    "queue": [],
    "printTime": true,
    "printUser": true,
    "formatterFunction": null,
    "supportsClassName": true,
    "showRts": true,
    "customCallbackFunction": null,
    "showInteractionLinks": true,
    "showImages": false,
    "useEmoji": false,
    "targetBlank": true,
    "lang": 'en',
    "permalinks": true,
    "dataOnly": false,
    "script": null,
    "scriptAdded": false
}




export { Feed };