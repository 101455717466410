'use strict';

import $ from 'jquery';
import { Plugin } from '../../../../../node_modules/foundation-sites/js/foundation.plugin';
import { GetYoDigits } from '../../../../../node_modules/foundation-sites/js/foundation.util.core';
import { MediaQuery } from '../../../../../node_modules/foundation-sites/js/foundation.util.mediaQuery';


class Video extends Plugin {
    _setup(element, options) {
        this.$element = element;
        this.options = $.extend({}, Video.defaults, this.$element.data(), options);
        this.$playButton = this.$element.find(`.${this.options.playButtonClass}`);
        this.$pauseButton = this.$element.find(`.${this.options.pauseButtonClass}`);
        this.$video = this.$element.find('video');
        this.$source = this.$element.find('source');
        this.sourceFile = this.$source.data('src');
        this.play = !this.options.autoplay;
        this.loaded = false;

        var id = this.$element[0].id || GetYoDigits(6, 'video-container');
        this.$element.attr({
            'id': id
        });
        this.doit;
        this._init();
    }

    _init() {
        this._mediaQuery();
        this._events();
    }

    _loadSource() {
        this.loaded = true;
        this.$source.attr('src', this.sourceFile);
        this.$video[0].load();
    }


    _mediaQuery() {
        if (MediaQuery.is('medium')) {
            this._loadSource();
        }
        if (MediaQuery.is(this.options.videoOn)) {
            this.play = !this.options.autoplay;
            this._playPause();
        }
    }


    _events() {
        var _this = this;
        if (!this.options.autoplay) {
            this.$element.on('click', function(event) {
                _this._playPause();
            })
        }

        $(window).on('resize', function(evennt) {
            clearTimeout(_this.doit);
            _this.doit = setTimeout(_this._mediaQuery(), 300);
        });

        this.$video.on('ended', function(evennt) {
            if (!_this.options.loop) {
                _this.play = !_this.options.autoplay;
                _this._playPause();
            }
        });
    }



    _playPause(play) {

        if (this.play) {
            this.play = false;
            this.$video.prop('controls', false);
            this.$video[0].pause();
            this.$pauseButton.addClass('hide');
            this.$playButton.removeClass('hide');
        } else {
            this.play = true;
            if (!this.loaded) {
                this._loadSource();
            }
            this.$video.prop('preload', this.options.preload);
            this.$video.prop('controls', this.options.controls);
            this.$video[0].play();
            this.$pauseButton.removeClass('hide');
            this.$playButton.addClass('hide');
        }
    }

}

Video.defaults = {
    autoplay: false,
    controls: true,
    loop: false,
    preload: 'auto',
    playButtonClass: 'play-button',
    pauseButtonClass: 'pause-button',
    videoOn: 'small'
}

export { Video };